.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow{
  -webkit-transition: -webkit-transform .3s ease-out, opacity .3s ease-in;
  -moz-transition: -moz-transform .3s ease-out, opacity .3s ease-in;
  -o-transition: -o-transform .3s ease-out, opacity .3s ease-in;
  transition: transform .3s ease-out, opacity .3s ease-in;
}

.leaflet-cluster-spider-leg{
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset .3s ease-out, -webkit-stroke-opacity .3s ease-in;
  -moz-transition: -moz-stroke-dashoffset .3s ease-out, -moz-stroke-opacity .3s ease-in;
  -o-transition: -o-stroke-dashoffset .3s ease-out, -o-stroke-opacity .3s ease-in;
  transition: stroke-dashoffset .3s ease-out, stroke-opacity .3s ease-in;
}
